<template>
  <div class="app-form-view">
    <v-skeleton-loader
      height="50vh"
      type="table-heading, image"
      :loading="preparing"
    >
      <v-stepper
        v-if="value.state && stateItems"
        alt-labels
        class="mb-4"
        :value="step"
      >
        <v-stepper-header>
          <v-stepper-step
            v-for="(item, index) in stateItems"
            :complete="step > index + 1"
            :step="index + 1"
            :key="item.value"
          >
            {{ item.label }}
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
      <app-form @submit="onSubmit" :loading="loading" hide-save hide-cancel>
        <app-widget
          :app="app"
          :model="model"
          :title="`${prefixTitle} ${title}`"
          :loading="loading"
        >
          <template v-slot:actions>
            <slot name="actions" :mode="mode">
              <slot name="prefix-actions" :mode="mode" />
              <v-btn
                v-if="!hideEdit && isViewMode && !isApproved"
                color="secondary"
                @click="onEdit"
              >
                {{ $t('btn.edit') }}
              </v-btn>
              <v-btn
                v-if="isCreateMode || isEditMode"
                color="secondary"
                type="submit"
              >
                {{ $t('btn.save') }}
              </v-btn>
              <v-btn
                v-if="isCreateMode || isEditMode"
                class="gray--text"
                color="white"
                @click="onDiscard"
              >
                {{ $t('btn.discard') }}
              </v-btn>
              <v-btn
                color="secondary"
                v-if="value.state === 'draft' && isEditMode"
                @click="onSendToApprove"
              >
                {{ $t('btn.sentApprove') }}
              </v-btn>
              <v-btn
                color="secondary"
                v-if="isSentToApprove && isEditMode"
                @click="onApprove"
                >{{ $t('btn.approve') }}
              </v-btn>
              <v-btn
                color="error"
                v-if="isSentToApprove && isEditMode"
                @click="onReject"
                >{{ $t('btn.reject') }}
              </v-btn>
              <v-btn
                v-if="isApproved"
                color="secondary"
                @click="onResetToDraft"
              >
                {{ $t('btn.resetToDraft') }}
              </v-btn>
              <slot name="suffix-actions" :mode="mode" />
            </slot>
          </template>

          <!-- @slot default -->
          <slot :view="isViewMode" :mode="mode" />
        </app-widget>
        <template v-slot:actions><div /></template>
      </app-form>
    </v-skeleton-loader>
  </div>
</template>

<script>
import AppForm from '@components/AppForm'
import AppWidget from '@components/AppWidget'
import { objFormData } from '@utils/function'

export default {
  name: 'app-form-view',
  components: {
    AppForm,
    AppWidget
  },
  props: {
    app: {
      type: String,
      required: true
    },
    model: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    apiUrl: {
      type: String,
      required: true
    },
    listRoute: {
      type: Object,
      required: false
    },
    discardTo: {
      type: Object
    },
    value: {
      type: Object,
      required: true
    },
    multipart: {
      type: Boolean,
      default: false
    },
    stateItems: {
      type: Array
    },
    approveItems: {
      type: Array
    },
    hideEdit: {
      type: Boolean,
      default: false
    },
    prepareData: {
      type: Function,
      default: (value) => value
    },
    binds: {
      type: Object,
      default: () => ({})
    },
    isValid: {
      type: Boolean,
      default: true
    },
    validateMessage: {
      type: String,
      default: ''
    }
  },
  computed: {
    isViewMode() {
      return this.mode === 'view'
    },
    isCreateMode() {
      return this.mode === 'create'
    },
    isEditMode() {
      return this.mode === 'edit'
    },
    isApproved() {
      return this.value.state === 'approved'
    },
    prefixTitle() {
      return {
        view: this.$t('label.view'),
        create: this.$t('label.create'),
        edit: this.$t('label.edit')
      }[this.mode]
    },
    recordId() {
      return this.$route.params.id
    },
    step() {
      return (
        this.stateItems.map((item) => item.value).indexOf(this.value.state) + 1
      )
    },
    isSentToApprove() {
      return this.value.state === 'sent_to_approve'
    }
  },
  data() {
    return {
      mode: 'create',
      preparing: true,
      loading: false
    }
  },
  created() {
    if (this.recordId) {
      this.mode = 'view'
      this.getRecord()
    } else {
      this.preparing = false
    }
  },
  methods: {
    getRecord() {
      this.$api({
        method: 'get',
        url: `${this.apiUrl}${this.recordId}/`,
        hideSuccessAlert: true
      })
        .then(({ data }) => {
          this.onInput(data.detail)
        })
        .finally(() => {
          this.preparing = false
        })
    },
    onEdit() {
      this.mode = 'edit'
    },
    async onResetToDraft() {
      try {
        this.loading = true
        const data = new FormData()
        data.append('state', 'draft')
        await this.$api({
          method: 'put',
          url: `${this.apiUrl}${this.recordId}/`,
          data
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.getRecord()
        this.loading = false
      }
    },
    onDiscard() {
      var listViewPath =
        this.$route.matched[this.$route.matched.length - 2].meta.childName.list

      if (this.listRoute) {
        listViewPath = this.listRoute
      }

      if (this.isCreateMode) {
        if (listViewPath !== undefined && listViewPath !== null) {
          this.$router.push(listViewPath)
        } else {
          this.$router.go(-1)
        }
      } else {
        this.mode = 'view'
      }
    },
    onSubmit() {
      this.loading = true
      const data = this.prepareData({ ...this.value })
      let options = {
        method: 'post',
        url: this.apiUrl,
        data
      }
      if (this.isEditMode) {
        options = {
          method: 'put',
          url: `${this.apiUrl}${this.recordId}/`,
          data
        }
      }

      if (this.multipart) {
        options.headers = { 'Content-Type': 'multipart/form-data' }
        options.data = objFormData(data)
      }
      this.editPath =
        this.$route.matched[this.$route.matched.length - 2].meta.childName.edit
      this.$api(options)
        .then((response) => {
          if (this.isCreateMode) {
            this.$router.push({
              ...this.editPath,
              params: { id: response.data.detail?.id },
              query: { ...this.$route.query }
            })
          }
          this.mode = 'view'
        })
        .catch(() => {
          this.$emit('submitError')
        })
        .finally(() => {
          this.getRecord()
          this.loading = false
        })
    },
    onInput(value) {
      this.$emit('input', value)
    },
    onSendToApprove() {
      if (this.isValid) {
        this.value.state = 'sent_to_approve'
        this.mode = 'edit'
        this.onSubmit()
      } else {
        this.$alert('custom', {
          icon: 'error',
          title: this.$t('alert.validationError'),
          text: this.validateMessage
        })
      }
    },
    onApprove() {
      this.isValid = true
      this.approveItems.forEach((prop) => {
        if (!this.value[prop] || false) {
          this.isValid = false
        }
      })

      if (this.isValid) {
        this.value.state = 'approved'
        this.mode = 'edit'
        this.onSubmit()
      } else {
        this.$alert('custom', {
          icon: 'error',
          title: this.$t('alert.validationError'),
          text: 'please approved each field before approved the proces'
        })
      }
    },
    onReject() {
      this.value.state = 'draft'
      this.mode = 'edit'
      this.onSubmit()
    }
  }
}
</script>
